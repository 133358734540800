import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

class AboutPage extends React.Component {
  render() {
    const description = `Old Man Pod is a radio program hosted by Old Man Time members, Burt Carlson and Abe Garvin. They've seen it all, from the ancient pyramids to trap music.`;

    return (
      <Layout location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <SEO title="About" description={description} featuredImage={this.props.data.file.publicURL} />
        <article>
          <header>
            <h1>About</h1>
            <Img fluid={this.props.data.file.childImageSharp.fluid} />
          </header>
          <p>
            Old Man Pod is a radio program hosted by the venerable and irritable Burt Carlson and{' '}
            <Link style={{ boxShadow: `none` }} to="/abe-garvin/">
              Abe Garvin
            </Link>
            . These two members of{' '}
            <a href="https://www.facebook.com/OLD-MAN-TIME-964132410322731/" rel="noopener noreferrer" target="_blank">
              Old Man Time
            </a>{' '}
            wonder into the radio station every so often to interview friends and enemies. They{`'`}re regrettably
            joined by their intern, Dustin D{`'`}Addato. Dustin is definitely an intern and in no way a producer at the
            station.
          </p>
          <p>
            These old timers have seen it all and know it all, from the ancient pyramids to trap music. So come on by
            for some meandering and rarely focused conversation.
          </p>
        </article>
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    file(absolutePath: { regex: "/burt-and-abe-about.jpg/" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
